import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import queryBannerImage from "../../hooks/query-banner-image"
import useMinimalConfig from "../../hooks/use-minimal-config"
import useSiteMetadata from "../../hooks/use-site-metadata"
import replaceSlashes from "../../../utils/replace-slashes"
import { FooterType } from "../../types"
import { isDesktopScreen } from "../../../utils/measure-screen-size"

type LayoutProps = {
  footer: FooterType
  [key: string]: any
}

const FooterCalicovered = ({ footer, ...props }: LayoutProps) => {
  const { footerNavigation: nav, basePath } = useMinimalConfig()
  const { siteImage, siteTitle, footerCopyrightText } = useSiteMetadata()
  const logoImage = queryBannerImage(footer?.logo || siteImage)
  const [height, setHeight] = useState(18)

  useEffect(() => {
    setHeight(isDesktopScreen() ? 36 : 18)
  })

  return (
    <footer className={`${footer.advanced?.class || `bg-footerBackground text-footerColor`} py-6 md:py-6 px-6 sm:px-6 lg:px-0`}>
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">
        <div className="2xl:grid grid-cols-2 gap-4 md:block">
          <div className="text-sm text-center">
            {logoImage && <img className="m-0 mx-auto" src="http://calicovered.org/images/sureco_logo.png" />}
            <p className="text-sm">CaliCovered.org is operating under and owned<br/>by SureCo Health and Life Insurance Agency Inc</p>
            <div className="my-2">
              {nav &&
                nav.map((item: any, itemIndex: any) => (
                  <Link key={item.slug} className="text-footerColor text-sm text-center" to={replaceSlashes(`/${basePath}/${item.slug}`)}>
                    {itemIndex > 0 && <span>&nbsp;|&nbsp;</span>}
                    {item.title}
                  </Link>
                ))}
            </div>
            <div className="mB-20">
              Contact Us<br></br>
              Headquarters:<br></br>
              201 E. Sandpointe Ave, Ste. 600<br></br>
              Santa Ana, CA 92707<br></br>
              Phone: (866)235-5515<br></br>
            </div>
          </div>

          {footerCopyrightText && <div className="text-sm text-center text-footerColor mt-8">{footerCopyrightText}</div>}
        </div>
      </div>
    </footer>
  )
}

export default FooterCalicovered
